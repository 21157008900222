<template>
    <div class="right-side">
        <h2>{{$t("AccountPage.AccountAppeals.title")}}</h2>
        <div class="mb-4">{{$t("AccountPage.AccountAppeals.description")}}</div>

        <ul class="nav nav-pills">
            <li class="nav-item">
                <a @click.prevent="getByStatus(null)" class="nav-link active" id="home-tab" data-toggle="pill" href="#" aria-expanded="true">Все обращения</a>
            </li>
            <li class="nav-item">
                <a @click.prevent="getByStatus('INVINCIBLE')" class="nav-link" id="profile-tab" data-toggle="pill" href="#" aria-expanded="false">На рассмотрении</a>
            </li>
            <li class="nav-item">
                <a @click.prevent="getByStatus('ANSWERED')" class="nav-link" id="profile-tab" data-toggle="pill" href="#" aria-expanded="false">Отвеченые</a>
            </li>   
        </ul>

        <div class="mt-3">

            <div class="card mb-3" v-for="appeal in appeals" :key="appeal.id">
            <div class="card-header"> {{ appeal.appealType.nameRu }} </div>
            <div class="card-body">

                <div class="media">
                    <div class="media-body">
                        <p> {{ appeal.question }} </p>
                        <p>
                            Площадка: {{appeal.system.nameRu}}
                        </p>
                        <p>
                            Объект обращения: {{appeal.appealObject.nameRu}}
                        </p>
                        <p v-if="appeal.tenderNumber">
                            Номер объявления: {{appeal.tenderNumber}}
                        </p>
                        <p v-if="appeal.tenderDate">
                            Дата объявления: {{appeal.tenderDate}}
                        </p>
                        <p>
                            Дата обращения: {{appeal.appealDate}}
                        </p>

                        <div class="media mt-3" v-if="appeal.reply">
                        <a class="mr-3" href="#" @click.prevent>
                            <img src="@/assets/logo.png" style="width:50px;" alt="cmer">
                        </a>
                        <div class="media-body">
                            <p>{{appeal.reply}}</p>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
            </div>
        </div>
        
        <beat-loader 
            v-if="beatLoad" 
            class="loader-center" 
            :color="'#553DB4'" 
            :loading="beatLoad" 
            :size="20" 
            :sizeUnit="'px'"
            />

        <paginate
            v-model="currentPage"
            :page-count="pageCount"
            :click-handler="changePage"
            :prev-text="'&laquo;'"
            :next-text="'&raquo;'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
            >
        </paginate>

    </div>
</template>

<script>
export default {
    data() {
        return {
            beatLoad: false,
            page: 0,
            filter: {},
            totalPages: 0,
            appeals: []
        };
    },
    mounted() {
        this.onCreate();
    },
    methods: {
        onCreate() {
            this.getMyAppeals();
        },
        getByStatus(status) {
            this.filter.status = status;
            this.getMyAppeals();
        },
        getMyAppeals() {
            this.beatLoad = false;
            this.$store.dispatch("LOAD_MYAPPEALS", { page: this.page, filter: this.filter })
                .then(s => {
                    this.appeals = s.content;
                    this.page = s.number;
                    this.totalPages = s.totalPages;
                    this.beatLoad = false;
                })
                ;
        },
        changePage(num) {
            this.currentPage = num;
            this.getMyAppeals();
        },
    },
    computed: {
        currentPage: {
            get() {
                return this.page + 1;
            },
            set(val) {
                this.page = val - 1;
            }
        },
        pageCount() {
            return this.totalPages;
        }
    }
}
</script>